// RestaurantModal.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { supabase } from '../supabaseClient';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

Modal.setAppElement('#root'); // Set the app element for accessibility

const RestaurantModal = ({ isOpen, onRequestClose, restaurant }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [visitInfo, setVisitInfo] = useState({
    visit_status: '',
    notes: '',
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    if (restaurant && user) {
      fetchVisitInfo();
    }
  }, [restaurant, user]);

  const fetchVisitInfo = async () => {
    if (!restaurant || !user) return;

    try {
      const { data, error } = await supabase
        .from('visits')
        .select('*')
        .eq('restaurant_id', restaurant.id)
        .eq('user_id', user.id);

      if (error) {
        console.error('Error fetching visit info:', error.message);
        setError(error.message);
      } else if (data.length === 0) {
        setVisitInfo({
          visit_status: '',
          notes: '',
        });
      } else if (data.length > 1) {
        console.error('Unexpected multiple rows returned for visit info');
        setError('Unexpected multiple rows returned for visit info');
      } else {
        setVisitInfo({
          visit_status: data[0].visit_status,
          notes: data[0].notes || '',
        });
      }
    } catch (error) {
      console.error('Unexpected error fetching visit info:', error);
      setError('Unexpected error fetching visit info');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVisitInfo({
      ...visitInfo,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      navigate('/login');
      return;
    }

    try {
      if (visitInfo.visit_status === '') {
        // Delete the visit info if the default value is selected
        const { error } = await supabase
          .from('visits')
          .delete()
          .eq('restaurant_id', restaurant.id)
          .eq('user_id', user.id);

        if (error) {
          console.error('Error deleting visit info:', error.message);
          setError(error.message);
        } else {
          alert('Visit info deleted successfully');
          setVisitInfo({
            visit_status: '',
            notes: '',
          });
          onRequestClose(); // Close the modal
        }
      } else {
        // Upsert visit info if a valid option is selected
        const { data, error } = await supabase
          .from('visits')
          .upsert({
            visit_status: visitInfo.visit_status,
            notes: visitInfo.notes,
            restaurant_id: restaurant.id,
            user_id: user.id,
          }, {
            onConflict: ['restaurant_id', 'user_id']
          });

        if (error) {
          console.error('Error saving visit info:', error.message);
          setError(error.message);
        } else {
          alert('Visit info saved successfully');
          onRequestClose(); // Close the modal
        }
      }
    } catch (error) {
      console.error('Unexpected error saving visit info:', error);
      setError('Unexpected error saving visit info');
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="ReactModal__Content" overlayClassName="ReactModal__Overlay">
      <div className="container">
        <button className="close-button" onClick={onRequestClose}>X</button>
        <h2>Restaurant Details</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {restaurant && (
          <>
            <p>Name: {restaurant.name}</p>
            <p>Cuisine: {restaurant.cuisine}</p>
            <p>Address: {restaurant.address}</p>
            <p>City: {restaurant.city}</p>
            <p>Website: <a href={restaurant.website} target="_blank" rel="noopener noreferrer">{restaurant.website}</a></p>
            <form onSubmit={handleSubmit}>
              <div>
                <label>
                  Your Visit Status
                  <select 
                    name="visit_status" 
                    value={visitInfo.visit_status} 
                    onChange={handleChange}
                  >
                    <option value="" className="">I haven't been here before</option>
                    <option value="want-to-try" className="green-text">I want to try this</option>
                    <option value="want-to-return" className="green-text">I want to return here</option>
                    <option value="do-not-want-to-return" className="red-text">I do not want to return</option>
                    <option value="do-not-want-to-try" className="red-text">I do not want to try this</option>
                  </select>
                </label>
              </div>
              <div>
                <label>
                  Notes:
                  <textarea 
                    name="notes" 
                    value={visitInfo.notes} 
                    onChange={handleChange} 
                  />
                </label>
              </div>
              <button type="submit">Save</button>
            </form>
          </>
        )}
      </div>
    </Modal>
  );
};

export default RestaurantModal;
