import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="container">
      <div className="button-container">
        <Link to="/add-restaurant" className="button">Add Restaurant</Link>
        <Link to="/explore" className="button">Discover</Link>
      </div>
    </div>
  );
};

export default Home;
