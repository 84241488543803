import React, { useState } from 'react';
import { supabase } from '../supabaseClient';

const AddRestaurant = () => {
  const [restaurant, setRestaurant] = useState({
    name: '',
    cuisine: '',
    address: '',
    city: '',
    website: ''
  });

  const handleChange = (e) => {
    setRestaurant({ ...restaurant, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const slug = restaurant.name.toLowerCase().replace(/\s+/g, '-');
    const restaurantWithSlug = { ...restaurant, slug };
    const { data, error } = await supabase
      .from('restaurants')
      .insert([restaurantWithSlug]);
    if (error) console.log('Error inserting restaurant:', error);
    else console.log('Restaurant added:', data);
  };

  return (
    <div className="container">
      <h2>Register Your Restaurant</h2>
      <form onSubmit={handleSubmit}>
        <input name="name" type="text" placeholder="Restaurant Name" onChange={handleChange} />
        <select name="cuisine" onChange={handleChange}>
          <option value="">Select Cuisine</option>
          <option value="Pizza">Pizza</option>
          <option value="Cafe">Cafe</option>
          <option value="Donair">Donair</option>
          <option value="Taste of Edmonton">Taste of Edmonton</option>
        </select>
        <input name="address" type="text" placeholder="Address" onChange={handleChange} />
        <input name="city" type="text" placeholder="City" onChange={handleChange} />
        <input name="website" type="text" placeholder="Website" onChange={handleChange} />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default AddRestaurant;
