import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './pages/Home';
import AddRestaurant from './pages/AddRestaurant';
import Explore from './pages/Explore';
import RestaurantDetails from './pages/RestaurantDetails';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import Terms from './pages/Terms';
import { useAuth } from './contexts/AuthContext';
import { supabase } from './supabaseClient';
import './App.css';

const App = () => {
  const { user } = useAuth();

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.log('Error signing out:', error.message);
  };

  return (
    <Router>
      <div>
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/add-restaurant">Add Restaurant</Link>
            </li>
            <li>
              <Link to="/explore">Explore</Link>
            </li>
            {!user ? (
              <>
                <li>
                  <Link to="/signup">Sign Up</Link>
                </li>
                <li>
                  <Link to="/login">Log In</Link>
                </li>
              </>
            ) : (
              <li>
                <button onClick={handleSignOut} className="nav-button">
                  Sign Out
                </button>
              </li>
            )}
          </ul>
        </nav>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/add-restaurant" element={<AddRestaurant />} />
          <Route path="/explore" element={<Explore />} />
          <Route path="/restaurant-details/:slug" element={<RestaurantDetails />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
