import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { useAuth } from '../contexts/AuthContext';

const RestaurantDetails = () => {
  const { slug } = useParams();
  const { user } = useAuth();
  const [restaurant, setRestaurant] = useState(null);
  const [visitInfo, setVisitInfo] = useState({
    want_to_return: '',
    notes: '',
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchRestaurant();
  }, [slug]);

  useEffect(() => {
    if (restaurant && user) {
      fetchVisitInfo();
    }
  }, [restaurant, user]);

  const fetchRestaurant = async () => {
    try {
      const { data, error } = await supabase
        .from('restaurants')
        .select('*')
        .eq('slug', slug)
        .single();

      if (error) {
        console.error('Error fetching restaurant:', error.message);
        setError(error.message);
      } else if (!data) {
        setError('No restaurant found for the given slug');
      } else {
        setRestaurant(data);
      }
    } catch (error) {
      console.error('Unexpected error fetching restaurant:', error);
      setError('Unexpected error fetching restaurant');
    }
  };

  const fetchVisitInfo = async () => {
    if (!restaurant || !user) return;

    try {
      const { data, error } = await supabase
        .from('visits')
        .select('*')
        .eq('restaurant_id', restaurant.id)
        .eq('user_id', user.id);

      console.log('Visit data fetched:', data);
      
      if (error) {
        if (error.message === 'No rows found') {
          console.log('No visit info found for this user and restaurant');
          setVisitInfo({
            want_to_return: '',
            notes: '',
          });
        } else {
          console.error('Error fetching visit info:', error.message);
          setError(error.message);
        }
      } else if (data.length === 0) {
        console.log('No visit info found, setting default values');
        setVisitInfo({
          want_to_return: '',
          notes: '',
        });
      } else if (data.length > 1) {
        console.error('Unexpected multiple rows returned for visit info');
        setError('Unexpected multiple rows returned for visit info');
      } else {
        console.log('Fetched visit info:', data[0]);
        setVisitInfo({
          want_to_return: data[0].want_to_return ? 'yes' : 'no',
          notes: data[0].notes || '',
        });
      }
    } catch (error) {
      console.error('Unexpected error fetching visit info:', error);
      setError('Unexpected error fetching visit info');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVisitInfo({
      ...visitInfo,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (visitInfo.want_to_return === '') {
        // Delete the visit info if the default value is selected
        const { error } = await supabase
          .from('visits')
          .delete()
          .eq('restaurant_id', restaurant.id)
          .eq('user_id', user.id);

        if (error) {
          console.error('Error deleting visit info:', error.message);
          setError(error.message);
        } else {
          console.log('Visit info deleted');
          alert('Visit info deleted successfully');
          setVisitInfo({
            want_to_return: '',
            notes: '',
          });
        }
      } else {
        // Upsert visit info if a valid option is selected
        const { data, error } = await supabase
          .from('visits')
          .upsert({
            want_to_return: visitInfo.want_to_return === 'yes',
            notes: visitInfo.notes,
            restaurant_id: restaurant.id,
            user_id: user.id,
          }, {
            onConflict: ['restaurant_id', 'user_id']
          });

        if (error) {
          console.error('Error saving visit info:', error.message);
          setError(error.message);
        } else {
          console.log('Visit info saved:', data);
          alert('Visit info saved successfully');
        }
      }
    } catch (error) {
      console.error('Unexpected error saving visit info:', error);
      setError('Unexpected error saving visit info');
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!restaurant) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <h2>Restaurant Details</h2>
      <p>Name: {restaurant.name}</p>
      <p>Cuisine: {restaurant.cuisine}</p>
      <p>Address: {restaurant.address}</p>
      <p>City: {restaurant.city}</p>
      <p>Website: <a href={restaurant.website} target="_blank" rel="noopener noreferrer">{restaurant.website}</a></p>
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Do you want to return?
            <select 
              name="want_to_return" 
              value={visitInfo.want_to_return} 
              onChange={handleChange}
            >
              <option value="">I haven't been here before</option>
              <option value="yes">Yes, I want to return</option>
              <option value="no">No, I do not want to return</option>
            </select>
          </label>
        </div>
        <div>
          <label>
            Notes:
            <textarea 
              name="notes" 
              value={visitInfo.notes} 
              onChange={handleChange} 
            />
          </label>
        </div>
        <button type="submit">Save</button>
      </form>
    </div>
  );
};

export default RestaurantDetails;
