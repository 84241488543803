// Explore.js
import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { useAuth } from '../contexts/AuthContext';
import RestaurantModal from '../components/RestaurantModal';

const Explore = () => {
  const { user } = useAuth();
  const [filterCuisine, setFilterCuisine] = useState('');
  const [filterVisit, setFilterVisit] = useState('');
  const [restaurants, setRestaurants] = useState([]);
  const [visits, setVisits] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    fetchRestaurants();
  }, [filterCuisine, filterVisit]);

  useEffect(() => {
    fetchVisits();
  }, [user, modalIsOpen]);

  const fetchRestaurants = async () => {
    let { data: restaurants, error } = await supabase
      .from('restaurants')
      .select('*');

    if (error) {
      console.error('Error fetching restaurants:', error);
      return;
    }

    if (filterCuisine) {
      restaurants = restaurants.filter((restaurant) => restaurant.cuisine === filterCuisine);
    }

    setRestaurants(restaurants);
  };

  const fetchVisits = async () => {
    if (!user) return;
    const { data: visits, error } = await supabase
      .from('visits')
      .select('*')
      .eq('user_id', user.id);

    if (error) {
      console.error('Error fetching visits:', error);
      return;
    }

    setVisits(visits);
  };

  const handleCuisineChange = (e) => {
    setFilterCuisine(e.target.value);
  };

  const handleVisitChange = (e) => {
    setFilterVisit(e.target.value);
  };

  const openModal = (restaurant) => {
    setSelectedRestaurant(restaurant);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedRestaurant(null);
    setModalIsOpen(false);
  };

  const hasVisited = (restaurantId) => {
    return visits.some(visit => visit.restaurant_id === restaurantId && visit.visit_status === 'want-to-return');
  };

  const filteredRestaurants = restaurants.filter((restaurant) => {
    if (filterVisit === 'want-to-revisit') {
      return visits.some(visit => visit.restaurant_id === restaurant.id && visit.visit_status === 'want-to-return');
    }
    if (filterVisit === 'never-been') {
      return !visits.some(visit => visit.restaurant_id === restaurant.id);
    }
    if (filterVisit === 'want-to-try') {
      return visits.some(visit => visit.restaurant_id === restaurant.id && visit.visit_status === 'want-to-try');
    }
    if (filterVisit === 'do-not-want-to-try') {
      return visits.some(visit => visit.restaurant_id === restaurant.id && visit.visit_status === 'do-not-want-to-try');
    }
    if (filterVisit === 'do-not-want-to-return') {
      return visits.some(visit => visit.restaurant_id === restaurant.id && visit.visit_status === 'do-not-want-to-return');
    }
    return true;
  });

  return (
    <div className="container">
      <select value={filterCuisine} onChange={handleCuisineChange}>
        <option value="">Filter by cuisine</option>
        <option value="Pizza">Pizza</option>
        <option value="Cafe">Cafe</option>
        <option value="Donair">Donair</option>
        <option value="Taste of Edmonton">Taste of Edmonton</option>
      </select>
      <select value={filterVisit} onChange={handleVisitChange}>
        <option value="">Show Preference</option>
        <option value="never-been">Never Been</option>
        <option value="want-to-try" className="green-text">I want to try this</option>
        <option value="want-to-revisit" className="green-text">I want to Revisit</option>
        <option value="do-not-want-to-try" className="red-text">I do not want to try this</option>
        <option value="do-not-want-to-return" className="red-text">I do not want to revisit</option>
      </select>
      <div className="restaurant-list">
        {filteredRestaurants.map((restaurant, index) => (
          <div key={index} className="restaurant-item">
            {hasVisited(restaurant.id) && (
              <div className="visited-banner">Visited</div>
            )}
            <h3>{restaurant.name}</h3>
            <p>{restaurant.cuisine}</p>
            <button onClick={() => openModal(restaurant)}>Update Visit Info</button>
          </div>
        ))}
      </div>
      <RestaurantModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        restaurant={selectedRestaurant}
      />
    </div>
  );
};

export default Explore;
