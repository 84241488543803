import React from 'react';

const Terms = () => {
  return (
    <div className="container">
      <h2>Terms of Use and Privacy Policy</h2>
      <p>
       Words and stuff
      </p>
    </div>
  );
};

export default Terms;
